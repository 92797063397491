import React from "react"
import { graphql } from "gatsby"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import '../css/quote-slider.css';

import quoteIcon from '../images/quoteIcon.png';
import LeftArrow from '../images/left-arrow.png';
import RightArrow from '../images/right-arrow.png';

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img 
      className={className} 
      style={{ ...style }}
      src={LeftArrow}
      onClick={onClick}
    />
  );
}

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img 
      className={className} 
      style={{ ...style }}
      src={RightArrow}
      onClick={onClick}
    />
  );
}

const QuoteSlider = ({ testimonials, section }) => { 

  var settings = {
    arrows:true,
    dots: false,
    infinite: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <div className="quoteSlider container">
    
      <Slider {...settings}>
      
        { testimonials &&
          testimonials.map(child => {
            const {firstName, lastName, position, testimonial, signature} = child.cptSingleTestimonial;

            return (
              <div key={child.id} className="container quoteContainer">
                <div className="section-intro smallGrid">
                    <h3 className="super">solutions</h3>
                    <h2 className="headline">A word of <span className={section}>Welcome!</span></h2>
                </div>

                <div className="grid smallGrid grid-bleed align-center">
                  <div className="col-sm-8">
                    <img src={quoteIcon} className="quoteIcon" />
                    <p className="quoteCopy" dangerouslySetInnerHTML={{ __html: '“ '+testimonial+' ”'}}></p>
                    <div className="grid smallGrid grid-bleed">
                      <div className="col-sm-5">
                        <p className="quoteName">{firstName} {lastName}</p>
                        <p className="quotePosition">{position}</p>
                      </div>
                      <div className="col-sm-7">
                        { signature &&
                          <img src={signature.localFile.publicURL} className="quoteSignature" />
                        }

                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    { child.featuredImage &&
                      <img src={child.featuredImage.node.localFile.publicURL} className="flex-img quotePerson" />
                    }

                  </div>
                </div>
              </div>
            )
          })
        }

      </Slider>
    </div>
  )
}

export default QuoteSlider